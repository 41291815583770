import $ from 'jquery';

(function (window, document, $, undefined) {
  $(document).ready(function() {
    $('.gform_button').on('click', function(){
      $(this).parent().addClass('button-loading')
    })
    $('[rel="button-form"]').on('click', function () {
      $(this).parent().next('[rel="kontakt-form"]').toggleClass('form-active')
        // $('[rel="kontakt-form"]').toggleClass('form-active')
    })
  });

})(window, document, $)
